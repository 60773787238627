//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';
import {WhereTable,RestTypes as _RestTypes} from '../../lib/model-meta';

export class Device{
	public id:number;
	public clientId:number;
	public uuid:string=uuid();
	public name:string;
	public notes:string|null=null;
	public iotName:string|null=null;
	public type:'conveyor'|'truck';
	public threshold:number=0;
	public slabDiameter:number=0;
	public density:number|null=null;
	public alertEmail:string|null=null;
	public minTargetSize:number|null=null;
	public maxTargetSize:number|null=null;
	public targetIntervals:number|null=null;
	public customSizeRanges:string="";
	public preferCustomSizes:boolean=false;
	public createdAt:DateTime=new DateTime();
}

export namespace Device{
	export const meta=new ModelMeta<keyof Props>(
		"Device",
		[],
		[
			{
				name: "id",
				type: "serial",
				decorators: {},
				uniqueKey: true,
				notNull: true
			},{
				name: "clientId",
				type: "int",
				decorators: {
					index: true
				},
				index: true,
				notNull: true
			},{
				name: "uuid",
				type: "string",
				decorators: {
					"rest.readonly": true,
					uniqueKey: true
				},
				uniqueKey: true,
				default: "uuid()",
				typeLength: [36,36],
				optional: true,
				notNull: true
			},{
				name: "name",
				type: "string",
				decorators: {},
				notNull: true
			},{
				name: "notes",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "iotName",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "type",
				type: "enum",
				members: [
					"conveyor",
					"truck"
				],
				decorators: {},
				notNull: true
			},{
				name: "threshold",
				type: "number",
				decorators: {},
				default: 0,
				optional: true,
				notNull: true
			},{
				name: "slabDiameter",
				type: "number",
				decorators: {},
				default: 0,
				optional: true,
				notNull: true
			},{
				name: "density",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "alertEmail",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "minTargetSize",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "maxTargetSize",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "targetIntervals",
				type: "int",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "customSizeRanges",
				type: "string",
				decorators: {},
				default: "",
				optional: true,
				notNull: true
			},{
				name: "preferCustomSizes",
				type: "boolean",
				decorators: {},
				default: false,
				optional: true,
				notNull: true
			},{
				name: "createdAt",
				type: "date",
				decorators: {
					"rest.readonly": true
				},
				default: "now()",
				optional: true,
				notNull: true
			}
		]
	);

	interface Props{
		id:number;
		clientId:number;
		uuid?:string;
		name:string;
		notes?:string|null;
		iotName?:string|null;
		type:'conveyor'|'truck';
		threshold?:number;
		slabDiameter?:number;
		density?:number|null;
		alertEmail?:string|null;
		minTargetSize?:number|null;
		maxTargetSize?:number|null;
		targetIntervals?:number|null;
		customSizeRanges?:string;
		preferCustomSizes?:boolean;
		createdAt?:DateTime;
	}


	meta.generateRest("device",[
		{
			name: "email",
			type: "string",
			decorators: {}
		},{
			name: "authToken",
			type: "string",
			decorators: {}
		}
	]);

	export interface ReadableProps{
		id:number;
		clientId:number;
		uuid?:string;
		name:string;
		notes?:string|null;
		iotName?:string|null;
		type:'conveyor'|'truck';
		threshold?:number;
		slabDiameter?:number;
		density?:number|null;
		alertEmail?:string|null;
		minTargetSize?:number|null;
		maxTargetSize?:number|null;
		targetIntervals?:number|null;
		customSizeRanges?:string;
		preferCustomSizes?:boolean;
		createdAt?:DateTime;
	}

	export interface ReadablePrimitiveProps{
		id:number;
		clientId:number;
		uuid?:string;
		name:string;
		notes?:string|null;
		iotName?:string|null;
		type:'conveyor'|'truck';
		threshold?:number;
		slabDiameter?:number;
		density?:number|null;
		alertEmail?:string|null;
		minTargetSize?:number|null;
		maxTargetSize?:number|null;
		targetIntervals?:number|null;
		customSizeRanges?:string;
		preferCustomSizes?:boolean;
		createdAt?:DateTime;
	}

	namespace body{

		interface WritableProps{
			clientId:number;
			name:string;
			notes?:string|null;
			iotName?:string|null;
			type:'conveyor'|'truck';
			threshold?:number;
			slabDiameter?:number;
			density?:number|null;
			alertEmail?:string|null;
			minTargetSize?:number|null;
			maxTargetSize?:number|null;
			targetIntervals?:number|null;
			customSizeRanges?:string;
			preferCustomSizes?:boolean;
		}

		export interface Auth{
			email?:string;
			authToken?:string;
		}

		export interface Get extends Auth{
			where:number;
			fields?:(keyof ReadableProps)[];
		}

		export interface Put extends Auth{
			where:number;
			fields:Partial<WritableProps>;
		}

		export interface Post extends Auth{
			fields:Partial<WritableProps>;
		}

		export interface Delete extends Auth{
			where:number;
		}

		export interface Count extends Auth{
			where?:WhereTable<keyof ReadablePrimitiveProps>;
		}

		export interface Search extends Auth{
			fields?:(keyof ReadablePrimitiveProps)[];
			where?:WhereTable<keyof ReadablePrimitiveProps>;
			range?:[number,number];
			sort?:Partial<Record<keyof ReadablePrimitiveProps,'+'|'-'>>;
		}
	}

	namespace _response{
		export type Get=Partial<ReadableProps>;
		export type Search=Partial<ReadablePrimitiveProps>[];
	}

	export interface RestTypes extends _RestTypes{
		body:{
			Auth:body.Auth;
			Get:body.Get;
			Put:body.Put;
			Post:body.Post;
			Delete:body.Delete;
			Count:body.Count;
			Search:body.Search;
		}

		response: {
			Get:_response.Get;
			Search:_response.Search;
		}
	}

}
