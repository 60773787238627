import { chart } from "@lib/angular/chart";
import { fragQuery } from "../../../../shared-models/frag-query";
import { DateTime } from "@lib/date-time";
import { getChartLineSeperationThreshold } from "@app/old-ui/dashboard/shared";

export const generateChartSummaryLines=( percents: Map<number, string>, input: fragQuery.timeline.sieveSizesAtPercents.Response ) => {
    const lines: chart.line.Series[] = [];
    if (input.summary) {
        for (const [percent, color] of percents) {
            let value = input.summary.dvalues[percent];
            if (value !== undefined) {
                lines.push({
                    type: 'line',
                    name: null,
                    data: [
                        [input.begin, value],
                        [input.end, value],
                    ],
                    color,
                    width: 1,
                    dash: [4],
                    includeInRange: [true, true],
                });
            }
        }
    }
    return lines;
}

export const generateToleranceSpecLines=(input: fragQuery.timeline.sieveSizesAtPercents.Response) => {
    return input.tags.map(
        (tag): chart.line.Series => ({
            type: 'line',
            name: 'Tolerance Spec',
            data: [
                [new DateTime(tag.begin * 1000), tag.toleranceSpecSize],
                [
                    tag.end ? new DateTime(tag.end * 1000) : input.end,
                    tag.toleranceSpecSize,
                ],
            ],
            color: 'white',
            width: 1,
            dash: [4],
            includeInRange: [false, false],
        })
    );
}

export type DataPoint = [
    DateTime,
    number,
    fragQuery.timeline.sieveSizesAtPercents.Interval
];

export const generateChartLines=(percents: Map<number, string>, input: fragQuery.timeline.sieveSizesAtPercents.Response):chart.line.Series[] => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }
    percents = new Map(percents);
    const series = generateChartSummaryLines(percents, input);

    const seperationThreshold = getChartLineSeperationThreshold(input.intervals);

    series.push(
        ...input.percents
            .sort((a, b) => a - b)
            .map((percent, i): chart.line.Series => {
                if (!percents.has(percent)) return null;
                const data: DataPoint[] = [];
                let prevInterval: fragQuery.timeline.sieveSizesAtPercents.Interval;
                for (const interval of input.intervals) {
                    const y = (interval.data[i] ?? 0);
                    if (!prevInterval) {
                        data.push([interval.begin, y, interval]);
                    } else if (
                        prevInterval.end.getTime() - interval.begin.getTime() >=
                        seperationThreshold
                    ) {
                        data.push([prevInterval.end, 0, interval]);
                        data.push([interval.begin, 0, interval]);
                    }
                    const x = interval.begin.lerp(interval.end, 0.5);
                    data.push([x, y, interval]);
                    prevInterval = interval;
                }

                return {
                    type: 'line',
                    name: 'D' + percent,
                    data,
                    color: percents.get(percent),
                    fill: true,
                    includeInRange: [false, true],
                };
            })
            .filter((v) => !!v)
            .reverse()
    );
    series.push(...generateToleranceSpecLines(input));

    return series;
}

export const generateChartSeries=(percents: Map<number, string>,input: fragQuery.timeline.sieveSizesAtPercents.Response):chart.line.Series[] => {
    
    if (input === undefined) {
        return undefined;
    }

    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    percents = new Map(percents);
    const series = generateChartSummaryLines(percents, input);

    series.push(
        ...input.percents
            .sort((a, b) => a - b)
            .map((percent, i): chart.line.Series => {
                if (!percents.has(percent)) return null;
                const data = input.intervals.map(
                    (interval) =>
                        <DataPoint>[
                            interval.begin,
                            (interval.data[i] ?? 0),
                            interval,
                        ]
                );
                return {
                    type: 'occurances',
                    name: 'D' + percent,
                    data,
                    color: percents.get(percent),
                    includeInRange: [false, true],
                };
            })
            .filter((v) => !!v)
            .reverse()
    );
    series.push(...generateToleranceSpecLines(input));
    return series;
}

export const generateQuantityChartLines = (input: fragQuery.timeline.quantity.Response) => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [DateTime, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.data;
        const valueB = b.data;

        data.push([a.end, valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end, 0]);
            data.push([b.begin, 0]);
            data.push([b.begin, valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.data;
    data.push([last.end, lastValue]);

    const lines: chart.line.Series[] = [];
    lines.push({
        type: 'line',
        name: 'Quantity',
        color: 'lightblue',
        fill: true,
        data,
    });
    if (lines[0].data.length === 1) {
        lines[0].data.unshift([input.intervals[0].begin, lines[0].data[0][1]]);
    }
    return lines;
}

export const generateQuantityChartSeries = (input: fragQuery.timeline.quantity.Response) => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    const bars: chart.line.Series[] = [];
    bars.push({
        type: 'occurances',
        name: 'Quantity',
        color: 'lightblue',
        data: input.intervals.map(interval => [interval.begin, interval.data]),
    });
    return bars;
}

export type TimeLineDataPoint = [
    number,
    number
];

export const generateTimelineChartSummaryLines=( percents: Map<number, string>, input: fragQuery.timeline.sieveSizesAtPercents.Response ) => {
    const lines: Array<Highcharts.SeriesOptionsType> =[];
    if (input.summary) {
        for (const [percent, color] of percents) {
            let value = input.summary.dvalues[percent];
            if (value !== undefined) {
                lines.push({
                    type: 'line',
                    name: null,
                    data: [
                        [input.begin.valueOf(), value],
                        [input.end.valueOf(), value],
                    ],
                    color,
                    lineWidth:2,
                    dashStyle:'ShortDash',
                    enableMouseTracking:false
                });
            }
        }
    }
    return lines;
}

export const generateTimelineToleranceSpecLines=(input: fragQuery.timeline.sieveSizesAtPercents.Response) => {
    return input.tags.map(
        (tag): Highcharts.SeriesOptionsType => ({
            type: 'line',
            name: 'Tolerance Spec',
            data: [
                [(tag.begin<input.begin.valueOf()) ? input.begin.valueOf() : tag.begin*1000, tag.toleranceSpecSize],
                [
                    tag.end ? tag.end*1000 : input.end.valueOf(),
                    tag.toleranceSpecSize,
                ],
            ],
            color: 'white',
            lineWidth: 2,
            dashStyle:'Dash'
        })
    );
}

export const generateTimelineChartLines=(chartType:"series"|"line",percents:Map<number, string>,input:fragQuery.timeline.sieveSizesAtPercents.Response,particleSizeUnit:string,addData?: (type: string) => void,plotClick?: (time:number) => void):Highcharts.Options => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }
    percents = new Map(percents);
    
    const series: Array<Highcharts.SeriesOptionsType> =[];

    const seperationThreshold = getChartLineSeperationThreshold(input.intervals);
    series.push(...generateTimelineChartSummaryLines(percents, input));

    series.push(
        ...input.percents
            .sort((a, b) => a - b)
            .map((percent, i): Highcharts.SeriesOptionsType => {
                if (!percents.has(percent)) return null;
                if(chartType==='line'){
                    const data: TimeLineDataPoint[] = [];
                    let prevInterval: fragQuery.timeline.sieveSizesAtPercents.Interval;
                    for (const interval of input.intervals) {
                        const y = (interval.data[i] ?? 0);
                        if (!prevInterval) {
                            data.push([interval.begin.valueOf(), y]);
                        } else if (
                            prevInterval.end.getTime() - interval.begin.getTime() >=
                            seperationThreshold
                        ) {
                            data.push([prevInterval.end.valueOf(), 0]);
                            data.push([interval.begin.valueOf(), 0]);
                        }
                        const x = interval.begin.lerp(interval.end, 0.5).valueOf();
                        data.push([x, y]);
                        prevInterval = interval;
                    }

                    return {
                        type: 'spline',
                        name: 'D' + percent,
                        data,
                        color: percents.get(percent),
                        lineWidth:4,
                        point: {
                            events: {
                                click: function (event) {
                                    if(plotClick){
                                        const clickedXValue = this.series.xAxis.toValue(event.chartX);
                                        plotClick(clickedXValue)
                                    }
                                }
                            }
                        }
                    };
                } else{
                    const data = input.intervals.map(
                        (interval) =>
                            <TimeLineDataPoint>[
                                interval.begin.valueOf(),
                                (i>1 ? interval.data[i]-interval.data[i-1] : interval.data[i]) ?? 0
                            ]
                            );
                            return {
                                type: 'column',
                                name: 'D' + percent,
                                data,
                                color: percents.get(percent),
                                point: {
                                    events: {
                                        click: function (event) {
                                            if(plotClick){
                                                const clickedXValue = this.series.xAxis.toValue(event.chartX);
                                                plotClick(clickedXValue)
                                            }
                                        }
                                    }
                                }
                            };
                }
            })
            .filter((v) => !!v)
            .reverse()
    );

    series.push(...generateTimelineToleranceSpecLines(input));

    let options:Highcharts.Options={
        rangeSelector: {
            enabled:false
        },

		credits: {
			enabled: false
		},

        chart:{
            backgroundColor:'transparent',
            events:{
                click: function(e:any) {
                    plotClick(e.xAxis[0].value)
                }
            }
        },

        exporting: {
            enabled: false
        },

        plotOptions: {
            series: {
                showInNavigator: true,
                dataLabels:[{
                    color:'#777'
                }]
            },
            column:{
                showInNavigator:true,
                stacking:'normal',
                
            }
        },

        tooltip:{
            shared: false,
            split: true,
            useHTML: true,
            pointFormatter: function () {
                const imageUrl = input.intervals[this.index - 1].imageUrl;
                const img = `<img src="${imageUrl}" onerror="this.src='../../assets/image_not_found.jpg'" height="200" width="300"/>`;
                return img;
            }
        },

        time:{
            timezoneOffset:new Date().getTimezoneOffset()
        },

        scrollbar: {
            liveRedraw: false,
            showFull:false,
            barBackgroundColor:'#5d7af6'
        },

        xAxis: {
            title: { 
                text: 'Time',
                style:{
                    fontSize:'1em',
                    color:'#777'
                },
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            // to prevent timeline axis from auto shrinking when data is not present for a timestamp
            breaks:[{
                breakSize:1000
            }],
            tickInterval: 3600 * 1000,
            events: {
                afterSetExtremes: function(event) {
                    if(addData){
                        if(event.trigger && event.userMin===event.dataMin){
                            addData('begin')
                        }
                        else if(event.trigger && event.dataMax===event.userMax){
                            addData('end')
                        }
                    }
                }
            }
        },

        yAxis:{
            title: { 
                text:`Particle Size (${particleSizeUnit})`,
                style:{
                    fontSize:'1em',
                    color:'#777'
                }
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            tickAmount:8,
            opposite:false
        },

        series: series
    };

    return options;
}

export const generateTimelineQualtityChartLines=(chartType:"series"|"line",input: fragQuery.timeline.quantity.Response,addData: (type: string) => void, title:string):Highcharts.Options=>{
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [number, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.data;
        const valueB = b.data;

        data.push([a.end.valueOf(), valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end.valueOf(), 0]);
            data.push([b.begin.valueOf(), 0]);
            data.push([b.begin.valueOf(), valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.data;
    data.push([last.end.valueOf(), lastValue]);

    if (data.length === 1) {
        data.unshift([input.intervals[0].begin.valueOf(), data[0][1]]);
    }

    const series: Array<Highcharts.SeriesOptionsType> =[];
    chartType==='line'
    ?
    series.push({
        type:'spline',
        name: 'Quantity',
        color: 'lightblue',
        data,
    })
    :
    series.push({
        type:'column',
        name: 'Quantity',
        color: 'lightblue',
        data,
    })

    let options:Highcharts.Options={
        rangeSelector: {
            enabled:false
        },

		credits: {
			enabled: false
		},

        chart:{
            backgroundColor:'transparent'
        },

        exporting: {
            enabled: false
        },

        plotOptions: {
            series: {
                showInNavigator: true
            }
        },

        time:{
            timezoneOffset:new Date().getTimezoneOffset()
        },

        scrollbar: {
            liveRedraw: false,
            showFull:false,
            barBackgroundColor:'#5d7af6'
        },

        xAxis: {
            title: { 
                text: 'Time',
                style:{
                    fontSize:'1em',
                    color:'#777'
                }
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            breaks:[{
                breakSize:1000
            }],
            events: {
                afterSetExtremes: function(event) {
                    if(event.trigger && event.userMin===event.dataMin){
                        addData('begin');
                    }
                    else if(event.trigger && event.dataMax===event.userMax){
                        addData('end');
                    }
                }
            }
        },

        yAxis:{
            title: { 
                text:`${title}`,
                style:{
                    fontSize:'1em',
                    color:'#777'
                }
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            tickAmount:8,
            opposite:false
        },

        series: series
    };
    return options;
}

export const generateTimelineQualtityChartLinesTons=(chartType:"series"|"line",input: fragQuery.timeline.quantity.Response,addData: (type: string) => void, title:string):Highcharts.Options=>{
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [number, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.tons;
        const valueB = b.tons;

        data.push([a.end.valueOf(), valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end.valueOf(), 0]);
            data.push([b.begin.valueOf(), 0]);
            data.push([b.begin.valueOf(), valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.tons;
    data.push([last.end.valueOf(), lastValue]);

    if (data.length === 1) {
        data.unshift([input.intervals[0].begin.valueOf(), data[0][1]]);
    }

    const series: Array<Highcharts.SeriesOptionsType> =[];
    chartType==='line'
    ?
    series.push({
        type:'spline',
        name: 'Quantity',
        color: 'lightblue',
        data,
    })
    :
    series.push({
        type:'column',
        name: 'Quantity',
        color: 'lightblue',
        data,
    })

    let options:Highcharts.Options={
        rangeSelector: {
            enabled:false
        },

		credits: {
			enabled: false
		},

        chart:{
            backgroundColor:'transparent'
        },

        exporting: {
            enabled: false
        },

        plotOptions: {
            series: {
                showInNavigator: true
            }
        },

        time:{
            timezoneOffset:new Date().getTimezoneOffset()
        },

        scrollbar: {
            liveRedraw: false,
            showFull:false,
            barBackgroundColor:'#5d7af6'
        },

        xAxis: {
            title: { 
                text: 'Time',
                style:{
                    fontSize:'1em',
                    color:'#777'
                }
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            breaks:[{
                breakSize:1000
            }],
            events: {
                afterSetExtremes: function(event) {
                    if(event.trigger && event.userMin===event.dataMin){
                        addData('begin');
                    }
                    else if(event.trigger && event.dataMax===event.userMax){
                        addData('end');
                    }
                }
            }
        },

        yAxis:{
            title: { 
                text:`${title}`,
                style:{
                    fontSize:'1em',
                    color:'#777'
                }
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            tickAmount:8,
            opposite:false
        },

        series: series
    };
    return options;
}